// packages
import React from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';
import Router from 'next/router';

// markup
import Form from '../blocks/forms/index';
import Input from '../blocks/forms/inputs/Input';
import Button from '../elements/Button';
import { H2 } from '../elements/Headers';
import P from '../elements/Paragraph';
import Icon from '../elements/icons';
import Footer from '../blocks/Page/footer';

// logic
import {
	getCookieString,
	getInfoFromCookie,
	intercomData,
	Redirect,
	setUserState,
} from '../lib/helpers';
import API from '../lib/API';
import { validateFields } from '../lib/helpers';
import { login, toggleLoader, toggleLoadingOverlay } from '../redux/actions';
import { segmentIdentify } from '../lib/helpers/segment';
import { segmentGroup } from '../lib/helpers/segment';
import { showError } from '../blocks/Toast';
import MfaModal from '../blocks/MfaModal';
import rpcShared from '@rockpapercoin/rpc-shared';
import { getHostnameAndProtocolFromRequest, PageHead } from '../blocks/helpers';

class Login extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			email: '',
			password: '',
			validate: {},
			mfaModalIsOpen: false,
			mfaToken: null,
		};
		this.handleChange = Form.handleChange.bind( this );
		this.login = this.login.bind( this );
	}

	static async getInitialProps( { req, res, query } ) {
		const hostnameAndProtocol = getHostnameAndProtocolFromRequest( req );
		const props = { hostnameAndProtocol };
		props.returnTo = query.returnTo;
		props.impersonateUser = query.impersonateUser;

		const [ , user ] = getInfoFromCookie( getCookieString( req ) );
		props.user = user;

		if ( user?.isLoggedIn ) {
			// logged in users don't belong here
			Redirect( res, '/' );
		}

		return props;
	}

	componentDidMount() {
		this.props.toggleLoader( false );
	}

	async login( e ) {
		e.preventDefault();
		this.props.toggleLoader( true );
		const validation = validateFields( {
			loginEmail: this.state.email,
			loginPassword: this.state.password,
		} );
		if ( !validation.validated ) {
			this.setState( {
				validate: validation.needsValidation,
			} );
			this.props.toggleLoader( false );
			return false;
		} else {
			this.setState( {
				validate: {},
			} );
		}

		const loginParams = {
			email: this.state.email,
			password: this.state.password,
		};

		if ( this.state.mfaToken && this.props.impersonateUser ) {
			loginParams.mfaToken = parseInt( this.state.mfaToken );
			loginParams.targetUserEmail = this.props.impersonateUser;
		}

		const { user, token, errors } = await API.login( loginParams );
		this.setState( { mfaModalIsOpen: false } );
		if ( errors ) {
			const message = errors[ 0 ].message;
			this.props.toggleLoader( false );
			if (
				message.includes( rpcShared.strings.errorMessages.invalidPassword ) ||
				message.includes( rpcShared.strings.errorMessages.userNotFound )
			) {
				this.setState( {
					validate: {
						login: 'You entered an incorrect email, password, or both.',
					},
				} );
			} else {
				errors.map( ( error ) => showError( error ) );
			}
			this.props.toggleLoader( false );
			return errors;
		}

		intercomData( user );

		this.props.onLogin( user, token );
		segmentIdentify( user );
		await segmentGroup( user );
		this.props.toggleLoader( false );
		if ( this.props.returnTo ) {
			Router.push( this.props.returnTo );
		} else {
			this.props.toggleLoadingOverlay( true );
			Router.push( '/' );
		}
	}

	async handleImpersonation( e ) {
		e.preventDefault();
		const res = await API.createMfaToken( {
			email: this.state.email,
			password: this.state.password,
			targetUserEmail: this.props.impersonateUser,
		} );
		if ( res.errors ) {
			res.errors.map( ( error ) => showError( error ) );
		} else {
			this.setState( { mfaModalIsOpen: true } );
		}
	}

	render() {
		return (
			<>
				<PageHead
					hostnameAndProtocol={ this.props.hostnameAndProtocol }
					title='Sign In'
					path='/Login'
				/>
				<main id='page-login' className='login__container'>
					<div className='login__form-wrapper'>
						<H2 className='h2--bold h2--secondary h2--centered login__header'>
							Welcome back!
						</H2>
						<P className='p--centered login__subtext'>Sign in and get to it.</P>
						<Form>
							<Input
								id='login-field__email'
								className='login-field__email'
								type='text'
								mapToState='email'
								placeholder='Email Address'
								label='Email'
								value={ this.state.email }
								onChange={ this.handleChange }
								validate={ this.state.validate.email }
								error={
									!!this.state.validate.email || !!this.state.validate.login
								}
							/>

							<Input
								id='login-field__password'
								className='login-field__password'
								type='password'
								mapToState='password'
								placeholder='Password'
								label='Password'
								value={ this.state.password }
								onChange={ this.handleChange }
								validate={ this.state.validate.password }
								error={
									!!this.state.validate.password || !!this.state.validate.login
								}
							/>

							<div className='login-link__reset-password'>
								<Link
									href={ {
										pathname: '/ResetPassword',
										query: {
											emailAddress: this.state.email,
										},
									} }
									as={ `/ResetPassword?emailAddress=${ this.state.email }` }
									className='legacyLink a--black a--opacity-20 a--right'
								>
									Forgot your password?
								</Link>
							</div>
							{ this.state.validate.login && (
								<div id='login-validation'>
									<Icon type='warning-2' />
									<P className='p--red login-validation__message'>
										{ this.state.validate.login }
									</P>
								</div>
							) }
							{ this.props.impersonateUser && (
								<Button
									id='login-button__submit'
									className='login-button__submit'
									text='Sign In'
									onClick={ ( e ) => this.handleImpersonation( e ) }
									disabled={
										!this.state.password ||
										!this.state.email ||
										this.props.loading
									}
								/>
							) }
							{ !this.props.impersonateUser && (
								<Button
									id='login-button__submit'
									className='login-button__submit'
									type='submit'
									text='Sign In'
									onClick={ this.login }
									disabled={
										!this.state.password ||
										!this.state.email ||
										this.props.loading
									}
								/>
							) }

							{ this.state.mfaModalIsOpen && (
								<MfaModal
									handleChange={ ( e ) =>
										this.setState( { mfaToken: e.target.value } )
									}
									login={ this.login }
									closeModal={ () => this.setState( { mfaModalIsOpen: false } ) }
								/>
							) }
						</Form>

						<div className='login-link__signup'>
							<Link href='/SignUp' className='legacyLink'>
								Don't have an account? Create one now!
							</Link>
						</div>
					</div>
					<Footer />
				</main>
			</>
		);
	}
}

const mapStateToProps = ( state, ownProps ) => ( {
	user: setUserState( state, ownProps ),
	loading: state.loader.loading,
} );

const mapDispatchToProps = ( dispatch ) => ( {
	onLogin: ( user, token ) => {
		dispatch( login( user, token ) );
	},
	toggleLoadingOverlay: ( active ) => {
		dispatch( toggleLoadingOverlay( active ) );
	},
	toggleLoader: ( active ) => {
		dispatch( toggleLoader( active ) );
	},
} );

export default connect( mapStateToProps, mapDispatchToProps )( Login );
